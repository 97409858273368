<template>
  <v-container>
    <ValidationObserver ref="objects" v-slot="{ invalid }">
      <v-form>
        <v-row>
          <v-col cols="12" md="6">
            <ValidationProvider name="From Date" rules="required">
              <DateInput v-model="from_date" label="From Date" :error-messages="errors" slot-scope="{ errors }" />
            </ValidationProvider>
          </v-col>
          <v-col cols="12" md="6">
            <ValidationProvider name="To Date" rules="required">
              <DateInput v-model="to_date" label="To Date" :error-messages="errors" slot-scope="{ errors }" />
            </ValidationProvider>
          </v-col>
          <v-col cols="12">
            <ValidationProvider name="Postcodes" rules="required_if_not:States">
              <v-textarea
                v-model="postcodes"
                label="Postcodes"
                rows="4"
                hint="Add comma seperated list"
                filled
                :disabled="!postcodesEnabled"
              ></v-textarea>
            </ValidationProvider>
          </v-col>
          <v-col cols="12">
            <ValidationProvider name="States" rules="required_if_not:Postcodes">
              <v-select
                v-model="selectedStates"
                :disabled="!statesEnabled"
                :items="states"
                item-value="id"
                item-text="title"
                :menu-props="{ maxHeight: '200' }"
                label="Select state(s)"
                multiple
                clearable="true"
                hint="Pick states"
                persistent-hint
                :error-messages="errors"
                slot-scope="{ errors }"
              ></v-select>
            </ValidationProvider>
          </v-col>
          <v-col cols="12">
            <ValidationProvider name="Type" rules="required">
              <v-select
                v-model="defaultFileType"
                :items="allowedFileTypes"
                :menu-props="{ maxHeight: '400' }"
                label="Type"
                auto-select-first
                flat
              ></v-select>
            </ValidationProvider>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="justify-end">
            <v-btn class="primary" @click="okAction()" :disabled="invalid">Export</v-btn>
          </v-col>
        </v-row>
      </v-form>
    </ValidationObserver>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import { extend, ValidationProvider, ValidationObserver } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';
import DateInput from '@/views/DateInput';
import store from '@/store/index';
import ReportService from '@/services/ReportService';
import { getError } from '@/utils/helpers';

extend('required', required);

export default {
  name: 'PostCodeExportDialog',
  components: {
    ValidationProvider,
    ValidationObserver,
    DateInput
  },
  props: {
    /**
     * The prefix to use for the report file name
     */
    reportFilenamePrefix: {
      type: String,
      default: 'Certificate of Attendence'
    },
    /**
     * Name used for report in Laravel REST API
     */
    reportName: {
      type: String,
      default: 'certificate-of-attendence'
    },
    /**
     * File types allowed for export
     */
    allowedFileTypes: {
      type: Array,
      default: () => ['pdf', 'csv']
    },
    /**
     * The default file type to select for the export
     */
    defaultFileType: {
      type: String,
      default: 'pdf'
    }
  },
  methods: {
    /**
     * Create the file name for the export
     */
    filename() {
      let filename = this.reportFilenamePrefix;
      return filename.replace(/[^a-z0-9]/gi, '-') + '.' + this.defaultFileType;
    },
    filetype() {
      return;
    },
    /**
     * Save method for the form
     */
    async okAction() {
      ReportService.getReport({
        report: this.reportName,
        filename: this.filename(),
        filetype: this.defaultFileType,
        postcodes: this.postcodes,
        states: this.selectedStates,
        from_date: this.from_date ?? '',
        to_date: this.to_date ?? ''
      }).catch(error => {
        store.dispatch('ui/snackbarError', getError(error));
      });
    },
    load() {
      store.dispatch('state/getStatesByCountry', 'au');
    }
  },
  mounted() {
    this.load();
  },
  computed: {
    ...mapGetters('state', ['loading', 'error', 'states']),
    postcodesEnabled() {
      return this.selectedStates.length === 0;
    },
    statesEnabled() {
      if (this.loading) {
        return false;
      }
      return this.postcodes == '';
    }
  },
  data: () => ({
    postcodes: '',
    selectedStates: [],
    from_date: null,
    to_date: null
  })
};
</script>
